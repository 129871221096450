import React, {useState, useEffect} from "react";
import config from "../../config";
import { navigate, graphql } from "gatsby";

import Layout from "../components/Layout";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";

import Header from "../components/Header";
import FlipCard from "../components/FlipCard";
import SEO from "../components/Seo";
import Loadable from "@loadable/component";
import Img from "gatsby-image";
import BackgroundImage from "gatsby-background-image";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-solid-svg-icons/faBuilding";
import { faDesktop } from "@fortawesome/free-solid-svg-icons/faDesktop";
import { faBullhorn } from "@fortawesome/free-solid-svg-icons/faBullhorn";
import { faChalkboard } from "@fortawesome/free-solid-svg-icons/faChalkboard";

const Footer = Loadable(() => import("../components/Footer"));
const SocialLinks = Loadable(() => import("../components/SocialLinks"));
const BookVisit = Loadable(() => import("../components/BookVisit"));
const Reviews = Loadable(() => import("../components/Reviews"));

library.add(...[faBuilding, faDesktop, faBullhorn, faChalkboard]);
const services = JSON.parse(`{ "data": [
  {
    "title": "Consultórios Premium",
    "icon": "building",
    "desc":
      "Mais que um consultório, um serviço chave na mão, localizado em plena rotunda do Saldanha em Lisboa. Soluções simples, eficazes e flexíveis.",
    "cta": {
      "desc": "Saber mais",
      "link": "planos#salas"
    }
  },
  {
    "icon": "desktop",
    "title": "Apoio Profissional",
    "desc":
      "Foque-se exclusivamente na sua arte e deixe os serviços administrativos para nós. Recepção de pacientes, gestão da sua agenda, confirmação de consultas, recepção de pagamentos etc",
    "cta": {
      "desc": "Saber Mais",
      "link": "planos#servicos"
    }
  },
  {
    "icon": "bullhorn",
    "title": "Marketing e Divulgação",
    "desc":
      "A atenção nunca esteve tão concentrada na internet como agora. \\nNos dias de hoje, presença digital, uma imagem forte e profissional, são cruciais para o crescimento de qualquer negócio",
    "cta": {
      "desc": "Saber Mais",
      "link": "planos#marketing"
    }
  }
]}`);

const IndexPage = ({ data, ...props }) => {
  const [loaded, setLoaded] = useState(false)
  const handleScroll = () => {
    !loaded && setLoaded(!loaded && window.scrollY > window.innerHeight * 1.3);
  }
  useEffect(() => {
    if (!loaded){
      window.addEventListener("scroll", handleScroll);
      document.querySelector("body").style.minHeight = "5000px";
    } else {
      document.querySelector("body").style.minHeight = "";
    }
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loaded]);
  return (
    <Layout>
      <SEO />
      <Header
        frontPage={true}
        title=""
        subtitle={
          <div
            onClick={() => {
              navigate(config.calendly);
            }}
          >
            E se pudesse ter{" "}
            <span className="underline">o seu consultório</span> no coração de Lisboa?
            <h3 className="mx-auto mt-2">
              Esqueça os "ses", porque agora já pode.
            </h3>
            <h3 className="mx-auto">Preços a partir de 4,90€ por hora.</h3>
          </div>
        }
        ctaLabel="Marcar visita"
        className=""
      />
      <div className="container-fluid  bg-fg-withLogo noGutters">
        <div className="row pr-5 pl-5 pt-5 justify-content-md-center">
          {services.data.map((s,k) => (
            <FlipCard
              key={`${k}-index-fpc`}
              icon={<FontAwesomeIcon icon={s.icon} />}
              title={s.title}
              onClick={() => {
                navigate(`${config.url}${config.pathPrefix}${s.cta.link}`);
              }}
              cta={
                <div
                  className="p-4 text-center"
                  style={{
                    width: "100%",
                  }}
                >
                  <button
                    style={{
                      position: "absolute",
                      left: "10%",
                      bottom: "1rem",
                      width: "80%",
                    }}
                    className="btn btn-primary  btn-cta"
                    href={`${config.url}${config.pathPrefix}${s.cta.link}`}
                  >
                    {s.cta.desc}
                  </button>
                </div>
              }
              frontTextSmall={s.desc}
              nrCols={3}
            />
          ))}
        </div>
        <div className="row pr-5 pl-5 pt-5 justify-content-md-center no-gutters">
          <div className="col col-sm-12 col-md-8 p-3 bg-primary-transparent-80">
            <Fade distance="100px" collapse bottom>
              <div className="p-5 ">
                <h1 className="text-fgColor">Queremos que sinta que</h1>
                <h1 className="text-fgColor">
                  este espaço <span className="underline">também é seu.</span>
                </h1>
              </div>
            </Fade>

            <h3 className="text-fgColor p-5 mt-5 text-center">
              Para isso damos-lhe inúmeras possibilidades para o auxiliar no seu
              trabalho.
            </h3>
            <Fade distance="100px" bottom>
              <h1 className="text-fgColor font-secondary text-right mt-5 ">
                Vá <span className="underline">sempre</span> além do que
              </h1>
              <div className="line-right" />

              <h1 className="text-fgColor font-secondary text-right">
                é esperado
              </h1>
            </Fade>
          </div>
          <BackgroundImage
            Tag="div"
            className={
              "col col-sm-12 col-md-4 no-gutters bg-primary-transparent"
            }
            fluid={data.ganesha.childImageSharp.fluid}
            backgroundColor={`#040e18`}
          ></BackgroundImage>
        </div>
        {loaded && (
          <>
            <div className="row mr-5 ml-5 justify-content-md-center no-gutters bg-secondary-transparent-80">
              <div className="d-sm-none col-sm-12">
                Auxilio no seu atendimento
              </div>
              <div className="col-md-10 col-sm-12 text-center mt-5 text-md-left">
                <div className="container">
                  <div className="row align-items-sm-center pr-5 mr-5 rResponsive">
                    <div className="col col-sm-12 pr-5 mr-5 rResponsive">
                      <h1 className="text-primary">RECEPCIONISTA</h1>
                      <p className="mt-5">
                        A nossa administrativa abrirá a porta aos seus pacientes
                        e
                      </p>
                      <p className="mb-5 ">
                        encaminhalos-à para a sala de espera.
                      </p>
                    </div>
                  </div>
                  <div className="row align-items-sm-center align-items-end pl-5 ml-5 lResponsive">
                    <div className="col ml-5 pl-5 text-center text-center text-md-right lResponsive">
                      <h1 className="text-primary">SALA DE ESPERA</h1>
                      <p className="mt-5">
                        Os seus pacientes podem tomar um café, um chá ou uma
                        água
                      </p>
                      <p className="mb-5">enquanto esperam pela consulta.</p>
                    </div>
                  </div>

                  <div className="row align-items-sm-center pr-5 mr-5 rResponsive">
                    <div className="col pr-5 mr-5 rResponsive">
                      <h1 className="text-primary">SOFTWARE</h1>
                      <p className="mt-5">
                        Pretendemos providenciar as melhores ferramentas para
                      </p>
                      <p className="">
                        que se foque na{" "}
                        <span className="destak">sua arte.</span>
                      </p>
                      <p className="mb-5">
                        Ex. Sistemas de gestão, permitir que os seus pacientes
                        façam agendamentos através do nosso site
                      </p>
                    </div>
                  </div>
                  <div className="row align-items-sm-center align-items-end ml-5 lResponsive">
                    <div className="col col-sm-12 text-center text-md-right">
                      <h1 className="text-primary">DIVULGAÇÃO</h1>
                      <p className="mt-5 ">
                        Iremos divulgar o trabalho de quem está connosco no
                        espaço,
                      </p>
                      <p className="mb-5">através dos nossos canais.</p>
                    </div>
                  </div>
                </div>
              </div>

              <Zoom>
                <div className="col-md-2 d-none d-md-block text-left">
                  <h1 className="rotate-90 bg-primary text-fgColor p-2 pl-3 pr-5 ">
                    {" "}
                    Auxilio no seu atendimento{" "}
                  </h1>
                </div>
              </Zoom>
            </div>
            <div className="row justify-content-md-center no-gutters pt-5 mt-5">
              <Reviews />
            </div>
            <div className="row mr-5 ml-5 justify-content-md-center no-gutters">
              <div className="col-sm-12">
                <Zoom>
                  <h1 className="text-primary text-center p-5 big">
                    O nosso espaço
                  </h1>
                </Zoom>
                <div className="container-fluid">
                  <Fade distance="100px" left>
                    <div className="row justify-content-md-center">
                      <div className="col-sm-12 col-md-6 ">
                        <h1 className="m-5">PROFISSIONAL</h1>
                        <p className="pl-5">
                          Temos todo um atendimento profissional para o auxiliar
                          no seu trabalho
                        </p>
                      </div>
                      <div className="col-sm-12 col-md-6 text-left">
                        {loaded && (
                          <Img
                            className={""}
                            alt="Na EMMIM Temos todo um atendimento profissional para o auxiliar no seu trabalho"
                            fluid={data.professional.childImageSharp.fluid}
                          />
                        )}
                      </div>
                    </div>
                  </Fade>

                  <Fade distance="100px" right>
                    <div className="row no-gutters justify-content-md-center">
                      <div className="col-sm-12 col-md-6 order-sm-1 order-md-2">
                        <h1 className="m-5">CRIATIVO</h1>
                        <p className="pl-5">
                          Queremos que tenha a possibilidade de dar largas à sua
                          imaginação e poder inovar dentro da sua área
                        </p>
                      </div>
                      <div className="col-sm-12 col-md-6  text-right order-sm-2 order-md-1">
                        {loaded && (
                          <Img
                            className={""}
                            alt="Na EMMIM Queremos que tenha a possibilidade de dar largas à sua imaginação e poder inovar dentro da sua área"
                            fluid={data.criativo.childImageSharp.fluid}
                          />
                        )}
                      </div>
                    </div>
                  </Fade>

                  <Fade distance="100px" left>
                    <div className="row no-gutters justify-content-md-center">
                      <div className="col-sm-12 col-md-6 ">
                        <h1 className="m-5">ADAPTÁVEL</h1>
                        <p className="pl-5">
                          Podemos adaptar as salas de acordo com as suas
                          necessidades.
                        </p>
                      </div>
                      <div className="col-sm-12 col-md-6  text-left">
                        {loaded && (
                          <Img
                            className={""}
                            alt="Na EMMIM podemos adaptar as salas de acordo com as suas necessidades"
                            fluid={data.adaptavel.childImageSharp.fluid}
                          />
                        )}
                      </div>
                    </div>
                  </Fade>

                  <Fade distance="100px" right>
                    <div className="row no-gutters mb-5 justify-content-md-center">
                      <div className="col-sm-12 col-md-6 order-sm-1 order-md-2">
                        <h1 className="m-5">ACONCHEGANTE</h1>
                        <p className="pl-5">
                          Temos um ambiente acolhedor e confortável tanto para
                          os terapeutas como para os seus pacientes
                        </p>
                      </div>
                      <div className="col-sm-12 col-md-6 text-right order-sm-2 order-md-1">
                        {loaded && (
                          <Img
                            className={""}
                            alt="Na EMMIM Temos um ambiente acolhedor e confortável tanto para
                          os terapeutas como para os seus pacientes"
                            fluid={data.aconchegante.childImageSharp.fluid}
                          />
                        )}
                      </div>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {loaded && (
        <>
          <div className="container-fluid gray-100-bg">
            <div className="row mr-5 ml-5 justify-content-md-center no-gutters pt-5">
              <div className="col-sm-12 mt-5">
                <Fade distance="100px">
                  <h1 className="text-primary text-center  mt-5 big">
                    Foque-se n<span className="destak">a sua arte</span>
                  </h1>
                  <h3 className="text-center mb-5 big">
                    deixe o resto <span className="destak">connosco</span>
                  </h3>
                </Fade>
              </div>
            </div>

            <div className="row mr-5 ml-5 justify-content-md-center  mt-5 mb-5 pt-5">
              <Fade left distance="100px">
                <div className="col-sm-12 col-md-4 text-center">
                  {loaded && (
                    <Img
                      alt="Na EMMIM Gerimos a sua agenda fazendo a marcação e confirmação das
                    suas consultas bem como receber os pagamentos e emitir as
                    facturas aos seus pacientes."
                      className={""}
                      fluid={data.agenda.childImageSharp.fluid}
                    />
                  )}
                  <h1 className="text-primary  mt-5 mb-5">Gestão da Agenda</h1>
                  <p>
                    Gerimos a sua agenda fazendo a marcação e confirmação das
                    suas consultas bem como receber os pagamentos e emitir as
                    facturas aos seus pacientes.
                  </p>
                </div>
              </Fade>
              <Fade bottom distance="100px">
                <div className="col-sm-12 col-md-4 text-center">
                  {loaded && (
                    <Img
                      className={""}
                      alt="Na EMMIM possibilitamos que os seus pacientes marquem as 
                    consultas através da sua página?"
                      fluid={data.page.childImageSharp.fluid}
                    />
                  )}
                  <h1 className="text-primary  mt-5 mb-5">Presença Digital</h1>
                  <p>
                    Já imaginou possibilitar os seus pacientes de marcar
                    consultas através da sua página?
                  </p>
                  <p>
                    Os nossos terapeutas terão uma página de apresentação com
                    possibilidade de marcar consultas bem como escrever artigos
                    para o <a href="https://profissionais.emmim.pt/">blog.</a>
                  </p>
                  <span>
                    Tudo isto com um único objectivo,{" "}
                    <div className="destak">divulgar o seu trabalho.</div>{" "}
                  </span>
                </div>
              </Fade>
              <Fade right distance="100px">
                <div className="col-sm-12 col-md-4 text-center">
                  {loaded && (
                    <Img
                      className={"Na EMMIM damos Apoio na Divulgação"}
                      alt=""
                      fluid={data.marketing.childImageSharp.fluid}
                    />
                  )}

                  <h1 className="text-primary mt-5 mb-5">
                    Apoio na Divulgação
                  </h1>
                  <p>
                    Já imaginou ter o seu próprio site, totalmente
                    personalizado, feito por especialistas e gestão das suas
                    redes sociais?
                  </p>
                  <p>
                    Já imaginou criar o seu próprio curso e vendê-lo para todo o
                    mundo?
                  </p>
                </div>
              </Fade>
            </div>
            <Fade bottom distance="100px">
              <div className="row mr-5 ml-5 justify-content-md-centermt-5 p-5 pt-5">
                <div className="col">
                  <h1 className="text-primary text-center">
                    O seu sucesso é o nosso sucesso!
                  </h1>
                  <h1 className="font-secondary text-primary text-center">
                    Porque...
                  </h1>
                  <h1 className="font-secondary text-primary text-center  pb-5 pl-5 pr-5 pt-0 ">
                    Trabalhamos em <span className="destak">conjunto!</span>
                  </h1>
                </div>
              </div>
            </Fade>

            <Zoom top>
              <div className="row  text-center justify-content-md-center">
                <div className="col">
                  <h2 className="p-5">Quando começamos a trabalhar juntos?</h2>
                  <a
                    href={`${config.calendly}`}
                    className="btn btn-cta btn-lg"
                    target="_blank"
                    title="Conhecer a EMMIM presencialmente"
                  >
                    Eu Quero!
                  </a>
                </div>
              </div>
            </Zoom>
          </div>
        </>
      )}
      {loaded && <BookVisit bg="calendar prices" />}
      <SocialLinks />
      <Footer />
    </Layout>
  );
};
export const LandingPage = graphql`
         query LandingPage {
           ganesha: file(relativePath: { eq: "ganesha.jpg" }) {
             childImageSharp {
               fluid {
                 ...GatsbyImageSharpFluid_withWebp
               }
             }
           }
           aconchegante: file(relativePath: { eq: "cozy.jpg" }) {
             childImageSharp {
               fluid {
                 ...GatsbyImageSharpFluid_withWebp
               }
             }
           }
           adaptavel: file(relativePath: { eq: "yoga.jpg" }) {
             childImageSharp {
               fluid {
                 ...GatsbyImageSharpFluid_withWebp
               }
             }
           }
           criativo: file(relativePath: { eq: "creative.jpg" }) {
             childImageSharp {
               fluid {
                 ...GatsbyImageSharpFluid_withWebp
               }
             }
           }
           professional: file(relativePath: { eq: "professional.jpg" }) {
             childImageSharp {
               fluid {
                 ...GatsbyImageSharpFluid_withWebp
               }
             }
           }
           agenda: file(relativePath: { eq: "agenda.jpg" }) {
             childImageSharp {
               fluid {
                 ...GatsbyImageSharpFluid_withWebp
               }
             }
           }
           page: file(relativePath: { eq: "paginaOnline.jpg" }) {
             childImageSharp {
               fluid {
                 ...GatsbyImageSharpFluid_withWebp
               }
             }
           }
           marketing: file(relativePath: { eq: "apoioMkt.jpg" }) {
             childImageSharp {
               fluid {
                 ...GatsbyImageSharpFluid_withWebp
               }
             }
           }
         }
       `;

export default IndexPage;
